import React from "react"
import { Container } from "@material-ui/core"
import Footer from "../components/footer/Footer"
import Nav from "../components/navigation/Nav"
import PageBanner from "../components/PageBanner"
import SEO from "../components/SEO"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import MuiAccordionDetails from "@material-ui/core/AccordionDetails"
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp"
import Typography from "@material-ui/core/Typography"
import { styled } from "@material-ui/core/styles"

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0
  },
  "&:before": {
    display: "none"
  }
}))

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
    transform: "rotate(90deg)"
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1)
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)"
}))

const PoliciesPage = ({ location }) => {
  const [expanded, setExpanded] = React.useState("panel1")

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <>
      <SEO
        title="Policies"
        description="Welcome to Kumite Gaming rules and policies page."
        keywords="KIT, KITX, summer bash, game galaxy, tennessee, texas, fgc, tournament, fighing games, esports"
      />
      <header>
        <Nav pathname={location.pathname} />
        <PageBanner title="Policies" />
      </header>
      <main style={{ background: "#d4d4d4", width: "100%", padding: "60px 0" }}>
        <Container maxWidth="lg">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>COVID - 19 Waiver and Liability</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>
                    Risk of exposure to COVID-19 exists in any public place
                    where people are present. COVID-19 is an extremely
                    contagious disease that can lead to severe illness and
                    death. According to the Centers for Disease Control and
                    Prevention, senior citizens and guests with underlying
                    medical conditions are especially vulnerable.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    By attending Kumite in Tennessee 2021, you voluntarily
                    assume all risks related to potential exposure to COVID-19.
                    We highly recommend guests get vaccinated before attending
                    Kumite in Tennessee 2021.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Wearing of masks is mandatory while actively playing
                    tournament matches or casuals matches in the Kumite
                    tournament area. Individually wrapped masks will be
                    available to attendees for free in the case they do not have
                    their own mask.
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography>Code of Conduct</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>
                    Engaging in assault, battery, physical harassment or abuse,
                    or any other physical contact with any other person without
                    their consent is strictly prohibited at Kumite in Tennessee
                    2021.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Pestering or stalking any other person or otherwise not
                    respecting any other person’s reasonable desire to be left
                    alone is strictly prohibited at Kumite in Tennessee 2021.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Engaging in discriminatory or hateful statements or
                    behavior, including any based on race, color, ethnicity,
                    nationality, citizenship, sex, sexual or romantic
                    orientation, gender identity, religion, disability,
                    neurodiversity, body size, or any other personal
                    characteristic is strictly prohibited at Kumite in Tennessee
                    2021.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Tampering with a tournament, fixing any match or bracket,
                    colluding, entering multiple times in a single tournament,
                    substituting or being substituted for any other player
                    mid-tournament or without good faith permission from the
                    organizer, using disallowed game code exploits, or any other
                    unsportsmanlike conduct or conduct that violates commonly
                    accepted FGC tournament etiquette is strictly prohibited at
                    Kumite in Tennessee 2021.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Any violations of the above prohibited actions can lead to
                    ejection from the event and banning from future Kumite
                    events at the sole discretion of the tournament organizers.
                    Violations should be reported to a staff member at the
                    tournament organizer desk.
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>Drugs and Weapons</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>
                    Possession or use of firearms, knives, fireworks,
                    explosives, ammunition, chemical weapons, or any other
                    weapons not listed is strictly prohibited at Kumite in
                    Tennessee 2021.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Possession or use of any illegal drug or narcotic is
                    strictly prohibited at Kumite in Tennessee 2021.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Smoking or vaping is prohibited at Kumite in Tennessee 2021.
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <Typography>Refund Policy</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>
                    Attendees may request a refund of registration fees until
                    the close of online registration on October 25. After the
                    close of online registration no refunds will be offered.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    In the event that Kumite in Tennessee 2021 is cancelled, all
                    registration fees will be refunded.
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
        </Container>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  )
}

export default PoliciesPage
